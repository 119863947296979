import { Link } from "gatsby"
import React, { useMemo } from "react"
import { getMode } from "../../redux/slices/modeSlice"
import { useSelector } from "react-redux"
import * as styles from "./glassparagraph.module.scss"
import Images from "../../constants/Images"
const GlassParagraph = ({
  readMore,
  name = "",
  title,
  image,
  className = "",
  paragraph,
  butaImage = true,
  children,
}) => {
  const mode = useSelector(getMode)
  const clsNamePerMode = useMemo(() => {
    if (mode) return styles.inner_glass_bottom + " " + styles[`bottom_${mode}`]
    return styles.inner_glass_bottom
  }, [mode])

  return (
    <>
      {title && <h2>{title}</h2>}
      <div
        className={`${styles.glass_paragraph} ${
          butaImage ? styles.with_image : ""
        }`}
      >
        <div className={styles.container + " " + className}>
          <div className={clsNamePerMode}></div>
          <div className={styles.inner_glass_top}>
            {image && <img src={image} alt="client_pp" />}
            <div>
              {" "}
              {name && (
                <>
                  <p>
                    <b>{name}</b>
                  </p>
                  <br />
                  <br />
                </>
              )}
              {paragraph ? <pre>{paragraph}</pre> : children}
              <div className={styles.bottom}>
                {readMore && <Link to={readMore}>read more</Link>}
              </div>
            </div>
          </div>
        </div>
        {butaImage && <img src={Images.yellowButa} />}
      </div>
    </>
  )
}

export default GlassParagraph
