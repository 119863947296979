import React, { useEffect, useState } from 'react'
import { BsFillShareFill } from 'react-icons/bs'
import { VscArrowLeft } from 'react-icons/vsc'
import { FaFacebookF, FaLinkedinIn, FaPinterestP, FaTwitter } from 'react-icons/fa'
import Layout from '../components/layout'
import Seo from '../components/seo'
import * as styles from '../assets/css/projects.module.scss';
import GlassParagraph from '../components/glassParagraph/glassParagraph'
import Ideas from '../components/ideas/ideas'
import Slider from '../components/slider/slider'
import { Strings } from '../constants/strings'
import { navigate } from 'gatsby'
import { apiProjects } from '../config/api/endpoints/projects'
import Spinner from '../components/spinner/spinner'
import { useSelector } from 'react-redux'
import { getMode } from '../redux/slices/modeSlice'
import { getLanguage } from '../redux/slices/languageSlice'
import { CSSTransition } from 'react-transition-group'
import { FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from 'react-share'
import colors from '../constants/colors'
import Image from '../components/assets/image'

const Singleproject = ({ id }) => {
    const mode = useSelector(getMode);
    const language = useSelector(getLanguage)
    const [activeImageLink, setActiveImageLink] = useState('');
    const [shareMenuOpen, setShareMenuOpen] = useState(false);

    const [project, setProject] = useState();

    useEffect(() => {
        if (id) {
            apiProjects.getSingleProject(id).then(({ data: { data: { attributes } } }) => {
                setProject(attributes);
            }).catch(err => {
                if (err.response.status === 404) {
                    navigate(-1);
                } else {
                    console.log(err);
                }
            })
        }
    }, [id])
    if (!project) return <Spinner />;
    return (
        <Layout>
            <Seo title={`${project ? project.name : 'Projects'} - ${Strings.companyName}`} />
            {project && <div className={styles.single_title}>
                <h2>{Strings.projects[language]}</h2>
                <p>{project.project_type.data && project.project_type.data.attributes.name}</p>
                <h3
                    style={{ color: mode === "dark" ? "white" : "black" }}>
                    {project.name}
                </h3>
                <button
                    onClick={() => setShareMenuOpen((prev) => !prev)}
                    style={{
                        color: mode === "dark" ? "white" : "black",
                        backgroundColor: shareMenuOpen ? colors.blue : ""
                    }}
                    className={styles.share}><BsFillShareFill /></button>
                <button onClick={() => navigate(-1)} className={styles.back}><VscArrowLeft /></button>
                <CSSTransition
                    timeout={300}
                    in={shareMenuOpen}
                    classNames="toptobottom"
                    unmountOnExit
                    mountOnEnter
                >
                    <div className={styles.share_menu}>
                        <PinterestShareButton
                            url={window.location.href.startsWith("https") ? window.location.href : "https://caspiansoft.com"}>
                            <FaPinterestP />
                        </PinterestShareButton>
                        <TwitterShareButton
                            url={window.location.href.startsWith("https") ? window.location.href : "https://caspiansoft.com"}>
                            <FaTwitter />
                        </TwitterShareButton>
                        <LinkedinShareButton
                            url={window.location.href.startsWith("https") ? window.location.href : "https://caspiansoft.com"}>
                            <FaLinkedinIn />
                        </LinkedinShareButton>
                        <FacebookShareButton
                            url={window.location.href.startsWith("https") ? window.location.href : "https://caspiansoft.com"}>
                            <FaFacebookF />
                        </FacebookShareButton>
                    </div>
                </CSSTransition>
            </div>}
            <br />
            <br />
            {project && <GlassParagraph paragraph={project.description} />}
            <br />
            <br />
            {project && project.screenshots.data && <Slider viewAll={false}>
                {project.screenshots.data.map(item => (
                    <Image onClick={() => setActiveImageLink(item.attributes.url)} className={styles.slider_image} key={item.id} src={process.env.GATSBY_STRAPI_API_URL + item.attributes.url} />
                ))}
            </Slider>}
            <br />
            <br />
            <Ideas title={Strings.haveAProjectInMind[language]} form />
            <CSSTransition
                timeout={300}
                classNames="opacity"
                in={activeImageLink.length}
                unmountOnExit
                mountOnEnter
            >
                <div className={styles.blackdrop} onClick={() => setActiveImageLink("")}>
                    {activeImageLink.length && <img className={styles.blackdrop_image} src={process.env.GATSBY_STRAPI_API_URL + activeImageLink} />}
                </div>
            </CSSTransition>
        </Layout>)
}

export default Singleproject