import React from 'react'
import Layout from '../components/layout'
import * as styles from '../assets/css/projects.module.scss';
import Ideas from '../components/ideas/ideas'
import Seo from '../components/seo';
import { Strings } from '../constants/strings';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { getMode } from '../redux/slices/modeSlice';
import { getLanguage } from '../redux/slices/languageSlice';

const Projects = () => {
    const language = useSelector(getLanguage)
    const mode = useSelector(getMode)
    const [selectOpen, setSelectOpen] = React.useState(false);


    return (
        <Layout>
            <Seo title={`Projects - ${Strings.companyName}`} />
            <h2>{Strings.projects[language]}</h2>
            <br />
            <br />
            <br />
            <div className={styles.buttons + " " + styles[`buttons_${mode}`]}>
                <div className={styles.select}>
                    <div onClick={e => setSelectOpen((prev) => !prev)} className={styles.select_active_value + " " + styles[`select_active_value_${mode}`]}> <button>{selectOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</button></div>
                    {selectOpen && <div className={styles.select_options}>
                    </div>}
                </div>
            </div>
            <br />
            <br />
            <Ideas form title={Strings.haveAProjectInMind[language]} />
        </Layout>
    )
}

export default Projects