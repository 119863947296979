import React from 'react'
import { Router } from '@reach/router';
import Projects from '../../dynamicpages/projects';
import Singleproject from '../../dynamicpages/singleproject';
const Route = () =>
    <Router basepath='/projects'>
        <Projects path="/" />
        <Singleproject path="/:id" />
    </Router>

export default Route